/* You can add global styles to this file, and also import other style files */
body {
  font-family: "Open Sans", sans-serif;
  color: #302f2f;
  position: relative;
  background: transparent;
}

body::before {
  content: "";
  position: fixed;
  background: #040404 url("./assets/img/bg.jpg") top right no-repeat;
  background-size: cover;
  left: 0;
  right: 0;
  top: 0;
  height: 100vh;
}
